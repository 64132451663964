import React from "react";
import { Grid } from "semantic-ui-react";
import { InputText } from "../InputText/InputText";
import { DropDown } from "../selectDropDown/DropDown";
import { TitleView } from "../titleView/TitleView";

const SimulationProcessingDetails = ({
  register,
  data,
  errors,
  processTypesWholeList,
  processingType,
  setProcessingType,
  setValue,
  facilityName,
  isValidate,
  facilityWholeList,
  setFacilityName,
}: any) => {
    
  return (
    <>
      <TitleView title="Processing Details" />
      <label className="textLabel">Processing Name</label>
      <br />
      <label className="textLabel">{data?.processingName}</label>
      <Grid>
        <Grid.Row divided>
          <Grid.Column computer={8} tablet={8} mobile={8}>
            <Grid.Column style={{marginTop: 20, marginBottom: 20}} computer={8} tablet={8} mobile={8}>
              <label className="textLabel">Processing Type</label>
              <br />
              <label className="textLabel">{processTypesWholeList?.find((types: any) => data?.processingType == types?.value)?.text}</label>
            </Grid.Column>
            <Grid.Column style={{marginBottom: 20}} computer={8} tablet={8} mobile={8}>
              <label className="textLabel">Facility Name</label>
              <br />
              <label className="textLabel">{facilityWholeList?.find((types: any) => data?.facilityId == types?.value)?.text}</label>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <label className="textLabel">Machine Model Number</label>
              <br />
              <label className="textLabel">{data?.machineModelNo}</label>
            </Grid.Column>
          </Grid.Column>
          <Grid.Column computer={8} tablet={8} mobile={8}>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <DropDown
                labelName={"Processing Type"}
                placeholder="Select Type"
                currentData={processTypesWholeList}
                defaultValue={processingType}
                customGridColumn={"customGridColomnTyp"}
                handleChangeState={(e: any, { value }: any) => {
                  setProcessingType(value);
                  setValue("processingType", value);
                }}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            >
              <DropDown
                labelName={"Facility Name*"}
                placeholder="Select Facility Name"
                currentData={facilityWholeList}
                defaultValue={facilityName}
                required
                validate={isValidate}
                errorMessage={"Facility name is required"}
                customGridColumn={"customGridColomnTyp"}
                handleChangeState={(e: any, { value }: any) => {
                  setFacilityName(value);
                  setValue("facilityName", value);
                }}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            >
              <InputText
                register={register}
                labelName={"Machine Model Number"}
                placeholder="Machine Model Number"
                name={`machineModelNo`}
              />
            </Grid.Column>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default SimulationProcessingDetails;
