import React, { useContext, useMemo, useState, useRef } from "react";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useReactFlow } from "reactflow";

import { InputText, DropDown } from "../../../components";
import { InputNumber } from "../../../components/InputText/InputNumber";
import {
  useCreateExternalTransport,
  useGetExternalTransportById,
  useUpdateExternalTransport,
} from "../../../api/general/transport";
import { useGetTransportationTypes } from "../../../api/static-data";
import useWindowDimensions from "../../../hooks/windowsSize";
import { DrawingContext } from "../SimulateDrawingTool";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

export const TransportBase = ({ modalData }: any) => {
  const {
    productId,
    nodeItemId,
    salesUnitId,
    setNodeItem,
    setChartNodes,
    saveDrawing,
    chartEdges,
    chartNodes,
  } = useContext(DrawingContext);
  const { deleteElements } = useReactFlow();
  const { height } = useWindowDimensions();
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const { nodeItem } = modalData;

  const customFromValidation = useRef<HandleValidation>(null);
  const customToValidation = useRef<HandleValidation>(null);

  const [defaultType, setDefaultType] = useState(0);

  const externalTransportId =
    nodeItem?.data?.reference?.externalTransportId || false;

  //get external transport data by id
  const { data, status, fetchStatus } =
    useGetExternalTransportById(externalTransportId);

  const {
    data: transportationTypesData,
    isSuccess: transportationTypesDataSuccess,
  } = useGetTransportationTypes();

  const transportationTypes = useMemo(() => {
    if (transportationTypesDataSuccess) {
      return transportationTypesData.map((item: any) => {
        return {
          key: item.id,
          value: item.id,
          text: item.transportation_name,
        };
      });
    }
  }, [transportationTypesData, transportationTypesDataSuccess]);

  //handle company data input data
  const values = useMemo(() => {
    const transportationType = data?.transportationType.toString() || 0;
    setDefaultType(transportationType.toString());
    return {
      externalTransportName: data?.externalTransportName || "",
      transportationType,
      from: data?.from || "",
      to: data?.to || "",
      weight: data?.weight || 0,
      distance: data?.distance || 0,
    };
  }, [nodeItemId, data]);

  const {
    register: externalRegister,
    formState: { errors: errorsUnit },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    defaultValues: values,
    values: values,
  });

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  //custom hook create external transport data
  const {
    isLoading: createExternalTransportLoading,
    mutate: createExternalTransport,
  } = useCreateExternalTransport();

  //custom hook update external transport data
  const { isLoading: updateExternalLoading, mutate: updateExternalTransport } =
    useUpdateExternalTransport();

  //save transport node data
  const saveTransportNodeData = (id: any, externalTransportName: string) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        label: `External Transport (${externalTransportName})`,
        reference: {
          externalTransportId: id,
        },
        description: `External Transport`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
  };

  //submit data
  const onSubmit = (data: any) => {
    if (productId && salesUnitId) {
      data.weight = Number(data.weight);
      data.distance = Number(data.distance);
      data.productId = productId;
      data.salesUnitId = salesUnitId;
      data.transportationType = Number(defaultType);
      if (externalTransportId) {
        let updateData = {
          id: externalTransportId,
          data: data,
        };
        updateExternalTransport(updateData, {
          onSuccess(data) {
            saveTransportNodeData(data?._id, data?.externalTransportName);
            successMessage("External transport updated successfully");
          },
        });
      } else {
        createExternalTransport(data, {
          onSuccess(data) {
            saveTransportNodeData(data?._id, data?.externalTransportName);
            successMessage("External transport created successfully");
          },
        });
      }
    } else {
      errorView("Please connect ingredient before create external transport");
    }
  };

  //handle loading
  if (
    (status == "loading" && fetchStatus == "fetching") ||
    createExternalTransportLoading ||
    updateExternalLoading
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            height: height * 0.9 - 100,
            overflowX: "hidden",
          }}
        >
          <Grid>
            <Grid.Row divided>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <p className="mb-0">External Transportation Name</p>
                    <p>{getValues("externalTransportName")}</p>
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <p className="mb-0">Transportation Type</p>
                    <p>{defaultType}</p>
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <p className="mb-0">From</p>
                    <p>{getValues("from")}</p>
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <p className="mb-0">To</p>
                    <p>{getValues("to")}</p>
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <p className="mb-0">Weight (kg)</p>
                    <p>{getValues("weight")}</p>
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="mb-1"
                  >
                    <p className="mb-0">Distance (km)</p>
                    <p>{getValues("distance")}</p>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <Grid>
                  <Grid.Column computer={16} tablet={8} mobile={16}>
                    <InputText
                      labelName={"External Transportation Name*"}
                      errorMessage="External Transportation Name is required"
                      name="externalTransportName"
                      register={externalRegister}
                      errors={errorsUnit.externalTransportName}
                      required
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="pt-0 pb-0"
                  >
                    <DropDown
                      labelName={"Transportation Type"}
                      placeholder="Transportation Type"
                      currentData={transportationTypes}
                      defaultValue={defaultType}
                      customGridColumn={"customGridColomnType"}
                      handleChangeState={(e: any, { value }: any) => {
                        setDefaultType(value);
                      }}
                      search
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputText
                      ref={customFromValidation}
                      register={externalRegister}
                      errors={errorsUnit.from}
                      labelName={"From"}
                      placeholder="From"
                      name="from"
                      errorMessage="From is required"
                      customRequired={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputText
                      ref={customToValidation}
                      register={externalRegister}
                      errors={errorsUnit.to}
                      labelName={"To"}
                      placeholder="To"
                      name="to"
                      errorMessage="To is required"
                      customRequired={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputNumber
                      control={control}
                      errors={errorsUnit.weight}
                      name="weight"
                      labelName={"Weight (kg)"}
                      disabled={true}
                      required={false}
                      errorMessage="Weight is required"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputNumber
                      control={control}
                      errors={errorsUnit.distance}
                      name="distance"
                      labelName={"Distance (km)"}
                      disabled={true}
                      required={true}
                      errorMessage="Distance is required"
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <MainBottomButtonView
          cancelStatus={true}
          deleteStatus={externalTransportId}
          saveButtonStatus={true}
          saveButton={() => {
            customFromValidation.current?.checkCustomRequired(getValues().from);
            customToValidation.current?.checkCustomRequired(getValues().to);
          }}
          cancelButton={() => setNodeItem(null)}
          deleteButton={() => setVisibleDeleteModal(true)}
          saveTitle={externalTransportId ? "Update Transport" : "Submit"}
          type="submit"
        />
      </form>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            ...data,
            status: "DISABLE",
          };
          let updateData = {
            id: externalTransportId,
            data: dataToDelete,
          };
          updateExternalTransport(updateData, {
            onSuccess(data) {
              onNodesDelete();
            },
          });

          setVisibleDeleteModal(false);
        }}
        title={`Delete External Transport`}
        subTitle="Are you sure you want to remove the external transport? Please consider you cannot recover these data after remove"
      />
    </>
  );
};
