import { Grid } from "semantic-ui-react";
import { TitleView } from "../titleView/TitleView";
import { InputNumber } from "../InputText/InputNumber";
import { CustomButton } from "../button/Button";

const SimulationMeasurementDetails = ({
  control,
  errors,
  customCapacityValidation,
  customInputValidation,
  customOutputValidation,
  addIngredientActive,
  setViewIngredient,
  data,
}: any) => {
  return (
    <>
      <TitleView title="Measurement Details" />
      <Grid>
        <Grid.Row divided>
          <Grid.Column computer={8} tablet={8} mobile={8}>
            <Grid>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <label className="textLabel">Capacity(kg/hr)</label>
              <br />
              <label className="textLabel">{data?.capacity}</label>
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <label className="textLabel">Input(kg)</label>
              <br />
              <label className="textLabel">{data?.input}</label>
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <label className="textLabel">Output(kg)</label>
              <br />
              <label className="textLabel">{data?.output}</label>
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <label className="textLabel">Waste/Side Stream (kg)</label>
              <br />
              <label className="textLabel">{data?.sideStream}</label>
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <label className="textLabel">Heat(MJ/kg)</label>
              <br />
              <label className="textLabel">{data?.heat}</label>
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <label className="textLabel">Electricity(kWh/kg)</label>
              <br />
              <label className="textLabel">{data?.electricity}</label>
            </Grid.Column>
            <Grid.Column style={{marginTop: 10}} computer={5} tablet={8} mobile={16}>
              <label className="textLabel">Water(m3)</label>
              <br />
              <label className="textLabel">{data?.water}</label>
            </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column computer={8} tablet={8} mobile={8}>
            <Grid>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <InputNumber
                ref={customCapacityValidation}
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Capacity(kg/hr)*"}
                placeholder="Capacity(kg/hr)"
                name={`capacity`}
                errors={errors?.capacity}
                errorMessage="Capacity is required"
                customRequired={true}
              />
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <InputNumber
                ref={customInputValidation}
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Input(kg)*"}
                placeholder="Input(kg)"
                name={`input`}
                errors={errors?.input}
                errorMessage="Input is required"
                customRequired={true}
              />
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <InputNumber
                ref={customOutputValidation}
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Output(kg)*"}
                placeholder="Output(kg)"
                name={`output`}
                errors={errors?.output}
                errorMessage="Output is required"
                customRequired={true}
              />
            </Grid.Column>
            <Grid.Column className="paddingRemoveTop" computer={5} tablet={8} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Waste/Side Stream (kg)"}
                placeholder="Waste/Side Stream per kg"
                name={`sideStream`}
              />
            </Grid.Column>

            <Grid.Column
              computer={5}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            >
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Heat(MJ/kg)"}
                placeholder="0"
                name={`heat`}
              />
            </Grid.Column>

            <Grid.Column
              computer={5}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            >
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Electricity(kWh/kg)"}
                placeholder="0"
                name={`electricity`}
                valueAsNumber={true}
              />
            </Grid.Column>
            <Grid.Column
              computer={5}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            >
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Water(m3)"}
                placeholder="0"
                name={`water`}
                valueAsNumber={true}
              />
            </Grid.Column>
            <Grid.Column
              computer={5}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            ></Grid.Column>
            {addIngredientActive && (
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <label className={`inputTextLable`}>
                  Associate ingredients
                </label>
                <CustomButton
                  customColumnStyle="processingCustomColumnStyle"
                  customButtonStyle="customBtn"
                  theme="green"
                  title={`+ ${"  "}Add Ingredients`}
                  onClick={() => setViewIngredient(true)}
                />
              </Grid.Column>
            )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default SimulationMeasurementDetails;
