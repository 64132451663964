import React, { useMemo, useState, useEffect } from "react";
import { Grid, Table, Accordion, AccordionContent } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  ChemicalDataCol,
  TransportTypeCol,
  TransportSimulateCol,
} from "../../config/constants";
import useWindowDimensions from "../../hooks/windowsSize";
import { InputNumber } from "../../components/InputText/InputNumber";
import {
  useDeleteChemicalData,
  useDeleteChemicalTransportData,
  usePostChemicalData,
  usePostChemicalTransportData,
  usePutChemicalData,
  usePutChemicalTransportData,
} from "../../api/chemical";

import { errorView, successMessage } from "../../helpers/ErrorHandler";
import ConfirmModal from "../../components/confirmViewModal/ConfirmModal";
import { LoadTableData, LoadTransportTableData } from "./ChemicalTableView";
import {
  CustomButton,
  CommonTable,
  DropDown,
  Switch,
  AccordionTitleView,
} from "../../components";

import "./ChemicalContent.scss";

export const SimulationChemicalContent = ({
  chemicalDataType,
  chemicalData,
  processId,
  TransportTypeList,
  refetchChemical,
  type,
  supplierDataHandle = false,
  handleOpenValidation = () => {},
  data,
}: any) => {
  const [chemicalTransportData, setChemicalTransportData] = useState<any>([]);
  const [defaultTransportType, setDefaultTransportType] = useState<any>(1);
  const [defaultChemicalType, setDefaultChemicalType] = useState<any>("1");
  const [defaultTransportView, setDefaultTransportView] = useState<any>(false);
  const [dataTransportView, setDataTransportView] = useState<any>();
  const [updateData, setUpdateData] = useState<any>({});
  const [enableEdit, setEnableEdit] = useState<any>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [updateTransportData, setUpdateTransportData] = useState<any>({});
  const [enableTransportEdit, setEnableTransportEdit] = useState<any>(false);
  const [visibleTransportDeleteModal, setVisibleTransportDeleteModal] =
    useState(false);
  const [deleteTransportData, setDeleteTransportData] = useState<any>({});

  const [filteredChemicals, setFilteredChemicals] = useState(chemicalDataType);

  const values = useMemo(() => {
    return {
      amount: 0,
    };
  }, []);

  const valuesTransport = useMemo(() => {
    return {
      distance: 0,
    };
  }, []);

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    reset: resetTransport,
    handleSubmit: submitTransport,
    setValue: setTransportValue,
    formState: { errors: transportError },
    control: transportControl,
  } = useForm({
    mode: "all",
    defaultValues: valuesTransport,
    values: valuesTransport,
  });

  const { mutate: postChemicalDataSet } = usePostChemicalData();
  const { mutate: putChemicalDataSet } = usePutChemicalData();
  const { mutate: deleteChemicalDataSet } = useDeleteChemicalData();
  const { mutate: postChemicalTransportDataSet } =
    usePostChemicalTransportData();
  const { mutate: putChemicalTransportDataSet } = usePutChemicalTransportData();
  const { mutate: deleteChemicalTransportDataSet } =
    useDeleteChemicalTransportData();
  const chemicalTransportDataLength = chemicalTransportData?.length > 0 || 0;

  //check chemical transport data insert validation
  const checkChemicalTransportValidation = (data: any) => {
    if (data.distance == 0) {
      errorView("Distance value must greater than 0");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setFilteredChemicals(chemicalDataType);
  }, [chemicalDataType]);

  //save transport data
  const saveTransportChemicalData = (data: any) => {
    if (checkChemicalTransportValidation(data)) {
      if (enableTransportEdit) {
        let transportDetails = {
          transportType: Number(defaultTransportType),
          distance: Number(data.distance),
        };
        let updateTransportDetails = {
          chemicalTransportId: updateTransportData?._id,
          type,
          supplierDataHandle,
          transportData: transportDetails,
        };

        putChemicalTransportDataSet(updateTransportDetails, {
          onSuccess(data) {
            let getIndex = chemicalTransportData.findIndex(
              (e: any) => e._id == updateTransportData?._id
            );
            const getTempData = [...chemicalTransportData];
            getTempData[getIndex] = data.data;
            setChemicalTransportData(getTempData);
            resetTransportData("Update");
          },
        });
      } else {
        if (updateData._id) {
          let transportData = {
            transportType: Number(defaultTransportType),
            distance: Number(data.distance),
          };
          let createChemicalTransport = {
            chemicalId: updateData._id,
            type,
            supplierDataHandle,
            transportData: transportData,
          };
          postChemicalTransportDataSet(createChemicalTransport, {
            onSuccess(data) {
              setChemicalTransportData((chemicalTransportData: any) => [
                ...chemicalTransportData,
                data.data,
              ]);
              resetTransportData("Create");
            },
          });
        }
      }
    }
  };

  //reset after change all data
  const resetData = (Status: String) => {
    reset();
    setChemicalTransportData([]);
    setDefaultTransportType(1);
    setDefaultChemicalType("1");
    setDefaultTransportView(false);
    refetchChemical();
    successMessage(`Chemical Data ${Status} Successfully`);
    setEnableEdit(false);
    setDeleteData({});
    setUpdateTransportData({});
    setUpdateData({});
  };

  //reset transport data
  const resetTransportData = (Status: String) => {
    resetTransport();
    setDefaultTransportType(1);
    successMessage(`Chemical Data ${Status} Successfully`);
    setEnableTransportEdit(false);
    setUpdateTransportData({});
    refetchChemical();
  };

  //reset all data
  const resetAllState = () => {
    reset();
    setChemicalTransportData([]);
    setDefaultTransportType(1);
    setDefaultChemicalType("1");
    setDefaultTransportView(false);
    refetchChemical();
    setEnableEdit(false);
    setDeleteData({});
    resetTransport();
    setDefaultTransportType(1);
    setEnableTransportEdit(false);
    setUpdateTransportData({});
    setUpdateData({});
  };

  //update chemical data
  const updateChemicalData = (data: any, allChemData: any) => {
    setFilteredChemicals(allChemData);
    setUpdateData(data);
    setDefaultChemicalType(data?.chemicalName.toString() || "1");
    setValue("amount", data?.amount || 0);
    setChemicalTransportData(data?.transportationTypes);
    setDefaultTransportView(data?.isTransportationDetailsAvailable || false);
    setEnableEdit(true);
    refetchChemical();
  };

  //update chemical transport data
  const updateChemicalTransportData = (data: any) => {
    setUpdateTransportData(data);
    setDefaultTransportType(data?.transportType || 1);
    setTransportValue("distance", data?.distance || 0);
    setEnableTransportEdit(true);
  };

  //check chemical data insert validation
  const checkChemicalDataValidation = (data: any) => {
    if (data.amount == 0) {
      errorView("Amount must greater than 0");
      return false;
    } else {
      return true;
    }
  };

  //create new chemical
  const createNewChemical = (data: any, createNew?: any) => {
    if (checkChemicalDataValidation(data)) {
      if (enableEdit) {
        let createChemicals = {
          chemicalId: updateData._id,
          type,
          supplierDataHandle,
          chemicalData: {
            ...updateData,
            chemicalName: Number(defaultChemicalType),
            amount: data.amount,
            isTransportationDetailsAvailable: defaultTransportView,
            transportationTypes: data.transportationTypes,
          },
        };
        putChemicalDataSet(createChemicals, {
          onSuccess(data) {
            resetData("Edit");
          },
        });
      } else {
        let createChemicals = {
          processingId: processId,
          type,
          supplierDataHandle,
          chemicalData: {
            chemicalName: Number(defaultChemicalType),
            amount: data.amount,
            isTransportationDetailsAvailable: createNew
              ? true
              : defaultTransportView,
            transportationTypes: [],
          },
        };
        postChemicalDataSet(createChemicals, {
          onSuccess(data) {
            if (createNew) {
              updateChemicalData(data.data, chemicalDataType);
              successMessage(`Chemical Data Create Successfully`);
            } else {
              resetData("Create");
            }
          },
        });
      }
    }
  };

  //delete data collect
  const deleteDataAction = (data: any) => {
    setDeleteData(data);
    setVisibleDeleteModal(true);
  };

  //delete transport data collect
  const deleteDataTransportationAction = (data: any) => {
    setDeleteTransportData(data);
    setVisibleTransportDeleteModal(true);
  };

  return (
    <>
      <AccordionTitleView
        accordionEnabled={false}
        title={"Chemicals"}
        plusIcon={false}
      />
      <div>
        <Grid>
          <Grid.Row divided>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid.Column computer={16} className="paddingRemoveTop">
                {data?.chemical &&
                  data?.chemical?.length > 0 &&
                  data?.chemical?.map((chemData: any, index: any) => {
                    const addedChemical = chemicalDataType?.find(
                      (chemical: any) => chemical.value == chemData?.chemicalName
                    );
                    return (
                      <>
                        <label className="textLabel">
                          Do you know the transportation route from chemical
                          facility to your facility?
                        </label>
                        <br />
                        <label className="textLabel">
                          {chemData?.isTransportationDetailsAvailable
                            ? "Yes"
                            : "No"}
                        </label>
                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="pesticideTable"
                          style={{ marginTop: 10, marginBottom: 20 }}
                        >
                          <CommonTable
                            tableHeaderData={TransportSimulateCol}
                            className="pesticideTable"
                            isSimulateView
                          >
                            {chemData?.transportationTypes &&
                            chemData?.transportationTypes?.length > 0 ? (
                              chemData?.transportationTypes?.map(
                                (typeData: any, index: any) => {
                                  return (
                                    <Table.Row className="tbleR" key={index}>
                                      <Table.Cell>
                                        <p>{addedChemical?.text || ""}</p>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <p>{chemData?.amount}</p>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <p>
                                          {
                                            TransportTypeList?.[
                                              typeData?.transportType
                                            ]?.text
                                          }
                                        </p>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <p>{typeData?.distance}</p>
                                      </Table.Cell>
                                    </Table.Row>
                                  );
                                }
                              )
                            ) : (
                              <Table.Row className="tbleR" key={index}>
                                <Table.Cell>
                                  <p>{addedChemical?.text || ""}</p>
                                </Table.Cell>
                                <Table.Cell>
                                  <p>{chemData?.amount}</p>
                                </Table.Cell>
                                <Table.Cell>
                                </Table.Cell>
                                <Table.Cell>
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </CommonTable>
                        </Grid.Column>
                      </>
                    );
                  })}
              </Grid.Column>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid className="chemicalDetailsInsertMain">
                <Grid.Column computer={13}>
                  <div className="chemicalDetailsInsertBox">
                    <form
                      onSubmit={handleSubmit((data) =>
                        createNewChemical(data, false)
                      )}
                    >
                      <Grid>
                        <Grid.Column computer={8}>
                          <DropDown
                            required={true}
                            labelName="Chemical Name*"
                            placeholder="Select Chemical"
                            currentData={filteredChemicals}
                            defaultValue={defaultChemicalType}
                            handleChangeState={(e, data: any) => {
                              setDefaultChemicalType(data?.value);
                            }}
                            onSearchChange={(e, data: any) => {
                              const filteredArray = chemicalDataType?.filter(
                                (chemical: any) =>
                                  chemical?.text
                                    ?.toLowerCase()
                                    .includes(data.searchQuery?.toLowerCase())
                              );
                              setFilteredChemicals(filteredArray);
                            }}
                            loading={false}
                            search
                          />
                        </Grid.Column>
                        <Grid.Column computer={8}>
                          <InputNumber
                            control={control}
                            errors={errors.amount}
                            labelName={"Amount used (kg/kg)"}
                            placeholder="Amount used (kg/kg)"
                            name="amount"
                            required={true}
                            errorMessage="Amount is required"
                          />
                        </Grid.Column>
                        <Grid.Column computer={16} className="paddingRemoveTop">
                          <p className="chemicalTitle">
                            Do you know the transportation route from chemical
                            facility to your facility?
                          </p>
                          <Grid>
                            <Grid.Column computer={5}>
                              <Switch
                                onClick={() => {
                                  if (updateData._id) {
                                    setDefaultTransportView(
                                      !defaultTransportView
                                    );
                                  } else {
                                    setDefaultTransportView(
                                      !defaultTransportView
                                    );
                                    let createData = {
                                      amount: getValues().amount,
                                    };
                                    createNewChemical(createData, true);
                                  }
                                }}
                                titleOne={"No"}
                                titleTwo={"Yes"}
                                titleOneValue={"No"}
                                titleTwoValue={"Yes"}
                                defaultValue={
                                  defaultTransportView ? "Yes" : "No"
                                }
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                      {defaultTransportView ? (
                        <>
                          <Grid>
                            <Grid.Column computer={6} tablet={16} mobile={16}>
                              <DropDown
                                required={true}
                                labelName={"Transportation Type"}
                                placeholder="Select Transportation Type"
                                currentData={TransportTypeList}
                                defaultValue={
                                  TransportTypeList[defaultTransportType].value
                                }
                                customGridColumn={"customGridColomnType"}
                                handleChangeState={(e: any, { value }: any) => {
                                  setDefaultTransportType(value);
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column computer={6} tablet={16} mobile={16}>
                              <InputNumber
                                control={transportControl}
                                errors={transportError.distance}
                                labelName={"Distance"}
                                placeholder="Distance"
                                name="distance"
                                required={true}
                                errorMessage="Distance is required"
                              />
                            </Grid.Column>
                            <Grid.Column
                              computer={4}
                              tablet={16}
                              mobile={16}
                              className="alignPlusButton"
                            >
                              <CustomButton
                                onClick={submitTransport(
                                  saveTransportChemicalData
                                )}
                                title={enableTransportEdit ? "Edit" : "+"}
                                theme="green"
                                type="submit"
                              />
                            </Grid.Column>
                          </Grid>
                          {chemicalTransportDataLength ? (
                            <Grid style={{ marginTop: "5px" }}>
                              <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                              >
                                <CommonTable tableHeaderData={TransportTypeCol}>
                                  <LoadTransportTableData
                                    chemicalTransportData={
                                      chemicalTransportData
                                    }
                                    TransportTypeList={TransportTypeList}
                                    updateChemicalTransportData={
                                      updateChemicalTransportData
                                    }
                                    deleteDataTransportationAction={
                                      deleteDataTransportationAction
                                    }
                                  />
                                </CommonTable>
                              </Grid.Column>
                            </Grid>
                          ) : null}
                        </>
                      ) : null}
                    </form>
                  </div>
                </Grid.Column>
                <Grid.Column computer={3} tablet={16} mobile={16}>
                  <CustomButton
                    onClick={handleSubmit((data) =>
                      createNewChemical(data, false)
                    )}
                    title={enableEdit ? "Edit" : "Add"}
                    theme="green"
                    type="submit"
                  />
                </Grid.Column>
              </Grid>
              <Grid style={{ marginTop: "20px" }}>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                  <CommonTable tableHeaderData={ChemicalDataCol}>
                    <LoadTableData
                      chemicalData={chemicalData}
                      chemicalDataType={chemicalDataType}
                      updateChemicalData={updateChemicalData}
                      deleteDataAction={deleteDataAction}
                    />
                  </CommonTable>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};
